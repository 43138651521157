.blog-card {
  padding: 20px 24px;
  margin: 8px 0 16px;
  background-color: #00337C;
  border-radius: 12px;
  a:hover {
    color: #00d865;
  }
  .title {
    text-transform: capitalize;
  }
  .description {
    margin: 10px 0 14px;
  }
  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 14px;
    .user-wrap {
      width: 230px;
      display: flex;
      .avatar {
        width: 50px;
        height: auto;
        margin-right: 8px;
        border-radius: 50%;
      }
      .text-wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
    .right-wrap {
      display: flex;
      align-items: center;
      .readtime {
        width: max-content;
      }
      .bookmark,
      .bookmark:hover {
        transition-duration: 200ms;
      }
      .fa-bookmark {
        color: #00d865;
      }
      .bookmark:hover .fa-bookmark {
        color: #00bd58;
      }
      .fa-solid.fa-bookmark {
        color: #00d865;
      }
    }
  }
}
