html {
  overflow-y: scroll;
}

.bloglab {
  a {
    text-decoration: none;
    color: #f2f2f2;
  }
  a:hover {
    color: #00d865;
  }
}

.bloglab-modal {
  background-color: #00000090;
  .modal-lg {
    width: 450px;
    margin-top: 10em;
  }
  .modal-content {
    background-color: #00337c;
    .nav-fill {
      border: none;
      margin: 10px 7px;
      .nav-item {
        margin: 0 2.5px;
        .nav-link {
          border: none;
          border-radius: 6px;
        }
      }
    }
    .modal-header {
      justify-content: center;
      padding: 16px 16px 4px;
    }
    .modal-body {
      margin: 0 4px;
      padding: 0px 16px 16px;
      .signin-method-wrap {
        display: flex;
        justify-content: center;
        button {
          width: 42px;
          height: 42px;
          border-radius: 100%;
          i {
            font-size: 17px;
          }
        }
      }
      .input-wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 10px 0;
        input {
          color: #e0e0e0;
          border-radius: 4px;
          border: none;
          background-color: #002b68;
          font-size: 17px;
          padding: 6px 10px;
          margin: 4px 0;
        }
      }
      .forgot-password {
        text-align: center;
        margin: 15px 0 0;
        a {
          color: #f2f2f2;
          text-decoration: none;
        }
        a:hover {
          text-decoration: underline;
        }
      }
    }
    .modal-footer {
      padding: 0;
      margin: 0;
    }
  }
}

.display-center {
  display: flex;
  justify-content: center;
}

code {
  background-color: #002961;
  border-radius: 5px;
  padding: 3px 6px;
}

.bloglab-card:hover {
  background-color: #003176;
}

.jumbotron {
  background-color: #00337c;
  border-radius: 12px;
  padding: 25px 35px;
  margin: 18px 16px;
  p,
  li,
  a {
    font-size: large;
  }
  h3 {
    margin-top: 20px;
    a {
      color: #f2f2f2;
      font-size: 1.75rem;
      text-decoration: none;
    }
    a:hover {
      color: #00d865;
    }
  }
}

.modal-open {
  padding-right: 0px !important;
}

::-webkit-scrollbar {
  width: 17px;
  height: 17px;
}

::-webkit-scrollbar-thumb {
  height: 6px;
  border: 3px solid #00337c00;
  background-clip: padding-box;
  border-radius: 10px;
  background-color: #00337c60;
  box-shadow: inset -1px -1px 0px #00337c00, inset 1px 1px 0px #00337c00;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #00337c80;
}

::-webkit-scrollbar-track {
  width: 24px;
  background: #07213e;
}

body {
  background-color: #0a2647 !important;
  color: #f2f2f2 !important;
}

.bloglab-bg-dark,
.bloglab-bg-dark:focus,
.bloglab-bg-dark:active {
  background-color: #0a2647;
  color: #eeeeee;
  caret-color: #f2f2f2;
}

.user-wrap {
  width: 230px;
  display: flex;
  .avatar {
    width: 50px;
    height: auto;
    margin-right: 8px;
    border-radius: 50%;
  }
  .text-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .date {
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
        "Segoe UI Symbol";
    }
  }
}

.link {
  text-decoration: none;

  padding: 4px 6px;
  margin: 1px;
  color: #eeeeee;
}

.btn.bookmark {
  font-size: larger;
  padding: 0.2rem 0.5rem;
  margin: 0 5px;
}

// .btn-primary {
//   background-color: #00d865;
//   color: #002961;
// }

.btn-outline-green {
  color: #00d865;
  border-color: #00d865;
}
.btn-outline-green:hover {
  color: #0f0f0f;
  background-color: #00d865;
}
.btn-outline-green:focus {
  box-shadow: 0 0 0 0.25rem #19875490;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #13005a;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.modal-wrap {
  background-color: #ffffff;
  border-radius: 12px;
  width: 350px;
  height: max-content;
  padding: 16px;
  color: #222222;
  input {
    border: none;
  }
}

.bloglab-container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 65px);
}

.overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
