.side-nav {
  display: flex;
  flex-direction: column;
  min-width: 200px;
  margin: 16px -2px;
  .link {
    font-size: 19px;
    .icon {
      width: 30px;
      display: inline-block;
    }
  }
  .link:hover {
    background-color: #00ea6d91;
    border-radius: 6px;
    color: #111111;
    transition: 50ms;
  }
  .other {
    font-size: 18px;
    font-weight: bold;
    margin: 12px 10px 0;
  }
}

@media (max-width: 768px) {
  .side-nav {
    display: none;
  }
}