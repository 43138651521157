.listing-category-nav-wrap {
  margin-top: 20px;
  .listing-category-nav {
    display: flex;
    flex-direction: column;
  }
}
.listing-wrap {
  width: 850px;
  margin: 10px;
  a {
    color: #f2f2f2;
  }
  .listing-card {
    background-color: #00337c;
    border-radius: 12px;
    height: max-content;
    display: inline-block;
    padding: 15px 20px 20px;
    margin: 8px;
    width: 400px;
    transition: 150ms;
    cursor: pointer;
    .listing-content {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      height: 325px;
      .body {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
      }
      .user-wrap {
        display: flex;
      }
      .tags {
        margin: 8px -6px;
        .listing-tag {
          margin: 0 6px;
        }
      }
    }
  }
  .listing-card:hover {
    background-color: #003176;
  }
}

.bloglab-modal.listingtab-wrap {
  .modal-lg {
    margin-top: 0em;
  }
  .modal-content {
    .modal-footer {
      margin: 0 16px 10px;
    }
  }
}
