.tags-wrap {
  margin: 8px -5px;
  width: 350px;
  .tag {
    display: inline-block;
    margin: 4px 5px;
    padding: 3px 10px;
    border-radius: 30px;
    width: max-content;
  }
}

.display-inline-wrap {
  display: inline-block;
}

.tag-list-wrap {
  margin: 16px;
  .tag-list-header {
    display: flex;
    justify-content: space-between;
    width: 1054px;
    margin-bottom: 10px;
    h2 {
      width: 200px;
    }
    form {
      width: 300px;
    }
  }
  .tag-card-wrap {
    margin: -6px -6px 10px;
    .tag-card {
      a {
        color: #f2f2f2;
        text-decoration: none;
      }
      a:hover {
        color: #f2f2f2;
        text-decoration: underline;
      }
      display: block;
      border-radius: 10px;
      background-color: #00337c;
      width: 340px;
      padding: 20px 25px;
      margin: 8px;
      height: max-content;
      p {
        margin: 10px 0 18px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
      }
      .footer-wrap {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .icon {
          position: absolute;
          right: 0;
          width: 60px;
          height: auto;
          transform: rotate(-10deg);
        }
      }
    }
  }
}
