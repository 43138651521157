.container {
  display: flex;
  flex-direction: row;
  .blog-list {
    width: 100%;
    margin: 8px;
    .category {
      display: flex;
      margin: 4px 0;
      .link {
        font-size: 20px;
        color: #f3f3f3;
        text-decoration: none;
        margin: 0 8px;
      }
      .link:hover {
        color: #00db65;
      }
    }
  }
  .listings-wrap {
    width: 450px;
    margin: 16px 8px;
    .listings-header-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 4px;
      .header {
        width: max-content;
        font-size: 22px;
      }
      a {
        margin-bottom: 8px;
      }
    }
    .listing-tab {
      background-color: #00337c;
      border-radius: 12px;
      padding: 14px 16px;
      margin-bottom: 8px;
      cursor: pointer;
      .tags {
        margin-top: 6px;
      }
      .body {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
    .listing-tab:hover {
      background-color: #003176;
    }
  }
  .create-listing-btn {
    display: flex;
    justify-content: center;
    border-radius: 8px;
    width: 100%;
  }
}

@media (max-width: 1199px) {
  .listings-wrap {
    display: none;
  }
}