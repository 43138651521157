@import "./settings/variables.scss";

.login-body {
  height: calc(100vh - 65px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-body-wrap {
  height: 290px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-top: 16px;
}

.login-modal {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 400px;
  height: 475px;
  background-color: #ffffff;
  border-radius: 12px;
  z-index: 1;
}

.circle {
  border-radius: 500px;
}

.login-circle {
  width: 160px;
  height: 160px;
  background-color: #00d865;
  margin-bottom: -90px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  border: 8px solid #0a2647;
}

.custom-vec {
  font-size: 84px;
  color: #0a2647;
  margin-bottom: 6px;
}

.modal-name {
  font-size: 30px;
  color: #333333;
}

.signin-btn:hover {
  transition: 100ms;
  background-color: #00bd58;
}

.reg-query {
  font-size: 15px;
  color: #757575;
}

.blue-link:hover {
  text-decoration: underline;
}

.blue-link {
  color: #4874b6;
}

.login-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70px;
  margin: 0;
  background-color: #002c6b;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.footer-link {
  user-select: none;
  cursor: pointer;
  font-size: 16px;
  color: #e3e3e3;
  padding: 4px 6px;
  margin-bottom: 4px;
}

.instructions {
  text-align: center;
  padding-bottom: 4px;
  color: #797979;
}
.popover {
  max-width: none;
  .popover-header {
    text-align: center;
    color: #222222;
  }
  .popover-body {
    padding: 0.6rem 0.8rem;
    .help {
      display: flex;
    }
    .modal-tooltip-wrap {
      display: flex;
      justify-content: center;
      .modal-tooltip {
        background-color: #0a2647;
        border-radius: 5px;
        padding: 3px 6px;
        margin-top: 6px;
        color: #00d865;
      }
    }
  }
}

.bloglab-modal {
  .signin-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    margin: 20px 0 10px;
    color: #222222;
    width: 100%;
    height: 42px;
    background-color: #00d865;
    border-radius: 4px;
    border: none;
    cursor: pointer;
  }
  .signin-btn:hover {
    transition: 100ms;
    background-color: #00bd58;
  }
  .form-check {
    display: flex;
    justify-content: center;
    .form-check-input {
      margin-right: 5px;
    }
  }
}

.footer-link:hover {
  text-decoration: underline;
}
