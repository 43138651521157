@import "./settings/variables.scss";

.navbar-container {
  background-color: #00337C;
  display: flex;
  justify-content: center;
  .navbar-wrap {
    width: 1325px;
    height: 65px;
    display: flex;
    justify-content: space-between;
    padding: 8px 15px;
  }
  .navbar-left-wrap,
  .navbar-right-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    a {
      color: #f2f2f2;
      height: max-content;
      text-decoration: none;
      padding: 0 5px;
    }
  }

  .navbar-left-wrap {
    display: flex;
    justify-content: space-between;
    max-width: 405px;
    .search-bar {
      position: relative;
      margin: 0 10px;
      .form-control {
        padding-right: 45px;
      }
      .search-btn {
        position: absolute;
        right: 0;
      }
      .btn-outline-green {
        border: 1px solid #ffffff00;
        .fa-magnifying-glass {
          font-size: 17px;
          color: #afd3e2;
        }
      }
      .btn-outline-green:hover .fa-magnifying-glass {
        font-size: 17px;
        color: #0f0f0f;
      }
    }
    @media (max-width: 485px) {
      .search-bar {
        display: none !important;
      }
    }
  }
  
  .navbar-right-wrap {
    display: flex;
    justify-content: space-between;
    width: 135px;
  }
}
